@import 'src/scss/BaseTheme.scss';
.edit-admin-popup {
  .admin-apps-img {
        width: 30%;
        height: 100px;
  }
  .note {
      font-weight: 700;
      color:$galaxyBlue;
  }
  .est-time {
      color: $galaxyBlue;
  }
}