@import 'src/scss/BaseTheme.scss';

.alert-popup {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: fit-content;
    padding: 2rem;
    border-radius: 0.25rem;
    margin: auto;
    position: fixed;
    z-index: 5;
    top: 5.5rem;
    left: 0.75rem;
    right: 0.75rem;
    align-items: center;
    &.success {
      border: rem(1) solid $riverBlueDark;
      background-color: $riverBluelight;
    }
    &.error {
      border: rem(1) solid $errorDark;
      background-color: $errorLight;
    }
    &__close {
      height: 1rem;
      width: 1rem;
      padding: 0;
      background: none;
      &:hover,
      &.active {
        background: none !important;
        box-shadow: none !important;
      }
      img {
        height: 1rem;
        margin: 0;
        top: 0;
      }
    }
  }
@media (min-width: 576px) {
    .alert-popup {
              padding: 1.25rem 1.875rem 1.25rem 1.5rem;
              left: 0;
              right: 0;
              top: 6.625rem;
              &__close {
                margin-top: 0.125rem;
                img {
                    margin-bottom:0.625rem;
                }
              }
            }
}

  