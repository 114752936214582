@import 'src/scss/BaseTheme.scss';
#pease_note {
  width: 33%;
}
.forgot-password {
  .show-radio {
    padding-left: 0.9375rem;
    label {
      margin-left: 0.625rem;
    }
    .pleasenote {
      border: 0.0625rem solid $SandyBrown;
      border-radius: 0.25rem;
      background: $seaShellPeach;
      margin-top: 0.53125rem;
      margin-bottom: 0.53125rem;
      width: 50%;
      padding: 1rem;
      &-fp {
        color: $bullShot;
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }
}
