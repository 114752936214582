@import 'src/scss/BaseTheme.scss';
.footer {
  background-color: $aegisNavy;
  display: flex;
  padding: 1.6rem;
  position: sticky;
  top: 100%;
  .footer-links {
    color: $white;
  }
}
