@import 'src/scss/BaseTheme.scss';

.alert-style {
  max-height: 5.125rem;
  overflow-y: auto;
}

.ticker-container,
.ticker__element {
  overflow: hidden;
}

.ticker {
  border: 0.0625rem solid $aegisNavy;
}

.ticker-item {
  white-space: nowrap;
}
