@import 'src/scss/BaseTheme.scss';
@import 'src/scss/Typography.scss';

$primaryColor: $aegisOrange;

.admin-apps {
  .delete-icon {
    max-height: 2rem;
  }
  .admin-apps-img {
    width: 50%;
    height: 100px;
  }

  .delete-icon a {
    display: block;
    position: absolute;
    overflow: hidden;
    bottom: 1rem;
    right: 1rem;
  }

  .edit-icon {
    max-height: 2rem;
  }

  .edit-icon a {
    display: block;
    position: absolute;
    overflow: hidden;
    bottom: 1rem;
    left: 1rem;
  }
  .admin-block {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  .btn-add-apps {
    width: 26%;
    margin-top: 0rem !important;
    color: $aegisNavy;
    font-size: 1.25rem;
    &:hover {
      color: $primaryColor;
      text-decoration: none !important;
      text-transform: none;
    }
  }
  .app-content {
    background: none;
    color: $black;
    border-radius: 1rem;
    padding: 1.5625rem 1.5625rem 1rem 1.5625rem;
    svg {
      color: $black;
    }
    .app-icon {
      height: 6.25rem!important;
      width: 25%!important;
    }
  }
  .device-name {
    font-size: 1.3rem;
    margin-top: 1.5rem;
  }
}
