@import 'src/scss/BaseTheme.scss';
.id-container {
    &__content {
        margin-bottom:5rem;
    }
    img {
        height:100%;
        width:50%;
    }
    .delete-icon {
        position:absolute;
        bottom:6.25rem;
        right:0rem;
        width:1.875rem;
        height:1.875rem;
    }
}
@media (min-width: 576px) {
    .id-container {
        img {
            height:100%;
            width:50%;
        }
        &__content {
            margin-bottom:0rem;
        }
    }
}