@import 'src/scss/BaseTheme.scss';
@import 'src/scss/Typography.scss';

.apps-tab {
  .nav-tabs {
    & a {
      color: $black;
  
      &:hover {
        color: $black;
      }
  
      &.nav-link {
        &.active {
          border-top-color: transparent;
          border-left-color: transparent;
          border-right-color: transparent;
          &:hover {
            border-top-color: $grayLightDarker;
            border-left-color: $grayLightDarker;
            border-right-color: $grayLightDarker;
          }
          border-bottom: 0.25rem solid $black;
        }
  
        &:hover {
          border-top-color: $grayLightDarker;
          border-left-color: $grayLightDarker;
          border-right-color: $grayLightDarker;
        }
      }
    }
  }
  
  .app-box-item {
    border: 0.0625rem solid $grayLightDark;
    margin-right: 1.25rem;
    padding: 1.25rem;
    background: $white;
    color: $black;
    min-width: 11.25rem;
    svg {
      width: 1.875rem !important;
      height: 3.125rem;
    }
    a {
      color:$white;
    }
    .apps-img {
      height:100px;
      width:50%;
    }
  }
  svg {
    height: 47%!important;
  }
}

