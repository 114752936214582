@import 'src/scss/BaseTheme.scss';
.loader {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1100;
    &::after {
      content: "";
      border: 0.5rem solid $lightGray;
      border-radius: 50%;
      border-top: 0.5rem  solid $midNightBlueDark;
      width: 3.75rem;
      height: 3.75rem;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
    &.hide {
      display: none;
    }
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }