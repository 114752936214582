@import 'src/scss/BaseTheme.scss';

#wave {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3rem;
  width: 5rem;
  position: relative;
  padding: 0.875rem;
  background: $grayChatbot;
  border-radius: 1.25rem;

  &::after {
    display: block;
    content: ' ';
    position: absolute;
    left: -0.375rem;
    bottom: 0.25rem;
    border-radius: 0.125rem;
    border-left: 0.625rem solid transparent;
    border-right: 0.75rem solid transparent;
    border-bottom: 0.5625rem solid $grayChatbot;
  }

  .dot {
    width: 0.375rem;
    height: 0.375rem;
    margin: 0 0.25rem;
    background: $black;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: loadingFade 1s infinite;
    -moz-animation: loadingFade 1s infinite;
    -o-animation: loadingFade 1s infinite;
    animation: loadingFade 1s infinite;

    &:nth-child(1) {
      animation-delay: 0s;
    }

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}
