@import 'src/scss/BaseTheme.scss';
.devices {
    a { 
        color: $aegisNavy!important;
    }
    .no-devices {
        display:block;
        width:100%;
    }
    .device-box {
        padding: 1.875rem;
        svg {
            width: 2rem;
            height: 2rem;
        } 
    }
}