@import 'src/scss/BaseTheme.scss';
.personal-information {
  &__container {
    display: flex;
    border: 0.0625rem solid grey;
    padding: 1.25rem;
    flex-direction: row;
  }
}

.my-tasks {
  button {
    width: 100%;
    padding: 10px;
    font-size: 22px;
  }
  .task-approval {
    float: left;
  }
  .link-review {
    float: right;
  }
  .padTop10 {
    margin-top: 20px;
  }

  #table-scroll {
    height: 150px;
    overflow: auto;
    margin-top: 20px;
  }
  .th {
    display: block;
    border: 2px solid #000;
    width: 70px;

    white-space: nowrap;
  }
  .td {
    display: block;
    border: 2px solid #000;
    width: 4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
