@import 'src/scss/BaseTheme.scss';
.authorized-apps {
  .auth-app-container {
    display: flex;
    align-items: center;
    &__container {
        padding-left:1rem;
        div {
            //border: 2px solid $black;
            margin-top:0.125rem;
            padding:0.3125rem;
        }
    }
    svg {
      //border: 2px solid green;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}
@media (min-width: 576px) {
    .authorized-apps {
        .auth-app-container {        
          svg {
            //border: 2px solid green;
            width: 3.125rem;
            height: 3.125rem;
          }
        }
      }

}