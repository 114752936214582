@import 'src/scss/BaseTheme.scss';
.error {
    margin-bottom: 1.6875rem;
    &__block {
      overflow: hidden;
      border: 0.0625rem solid $errorDark;
      background-color: $errorDark;
      max-width: 34.625rem;
      margin: auto;
      max-width: none;
      border-radius: 0.625rem;
      border-right: 0.0625rem solid $errorDark;
      border-left: 0.0625rem solid $errorDark;
  
      &-header {
        color: $white;
        font-size: 1.125rem;
        letter-spacing: 0.00825rem;
        line-height: 1.6875rem;
        padding: 0.5rem 0.5rem 0.5rem 3.5rem;
        background: url('~assets/img/warning-outline.svg') no-repeat
          1.625rem center;
        background-size: 1.1.25rem;
      }
  
      &-body {
        padding: 1.125rem 1.125rem 1rem 1.5rem;
        background: $white;
        color: $grayExtraDark;
        font-size: 0.875rem;
        letter-spacing: 0.000625rem;
        line-height: 1.3125rem;
  
        &-section {
          p {
            color: $grayDark;
            font-size: 1rem;
            letter-spacing: rem(0.01);
            line-height: 1.5rem;
            margin-bottom: 0.125rem;
            margin-top: 1rem;
  
            &:first-child {
              margin-top: 0;
            }
          }
  
          ul {
            padding-left: 0.875rem;
  
            li {
              color: $grayExtraDark;
              font-size: 0.875rem;
              letter-spacing: 0.1rem;
              line-height: 1.3125rem;
            }
          }
        }
      }
    }
  }
 
  