@import 'src/scss/BaseTheme.scss';
.header {
  background-color: $aegisNavy;
  padding: 1.25rem;
  .translation {
    button {
      border:none;
      background:none;
    }
    .btn.btn-primary:hover:not(.hollow) {
      background:none;
    }
    #dropdownButton:focus {
      box-shadow: none !important;
      background-color: $aegisNavy !important;
      border: none !important;
      outline: none !important;
      color: $white !important;
    }
  }
  .show .btn-primary.dropdown-toggle, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .dropdown-item.active, .dropdown-item:active {
    color: $white!important;
    background: $aegisNavy!important;
    border:none!important;
    outline:none!important;
  }
}
#user-Initials-container {
  & button {
    background-color: transparent !important;
    border: none !important;

    &:focus,
    &:active {
      border: none !important;
      background-color: transparent !important;
    }
  }

  & .dropdown {
    & .dropdown-toggle:before {
      content: none;
    }

    & .welcome-text {
      padding-left: 1.625rem;
    }

    & .dropdown-menu.show {
      width: 13.875rem;
      & span > svg {
        min-width: 1.5rem;
      }
    }
  }

  & .user-initials-icon {
    background: $white;
    color: $black;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    line-height: 1rem;
    border-radius: 50%;
    
  }
}
