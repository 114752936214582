/*Global Classes used throughout the app that don't already
have a specific scss file associated with it go here*/

@import 'src/scss/BaseTheme.scss';

.container {
}
.mandatory {
  color: $errorDark;
}
.bold {
  font-weight: bold;
}

//separators
.separator-solid {
  border: 3px solid $tiber;
  background: $tiber;
}
.separator-light {
  border: 1px solid $grayLightDark;
  height: 0.0625rem;
  background: $grayLightDark;
}

// margin
.mb-6 {
  margin-bottom: 3.125rem;
}
.mb-7 {
  margin-bottom: 3.75rem;
}
.mb-8 {
  margin-bottom: 4.375rem;
}
.mb-9 {
  margin-bottom: 5rem;
}

// colors and font-text-colors
.green {
  color: $Lochinvar;
}
.red {
  color: $errorDark;
}
.black {
  color: $black;
}
.text-gray {
  color: $grayText-default;
}
.error-text {
  color: $errorDark;
}
.yellow {
  color: $yellow;
}

.error-input {
  border-color: $errorDark;
  padding-right: 3.5rem;
  background-size: 1.375rem;
}

.eye-icon {
  background: none;
  border: none;
  display: block;
  position: absolute;
  z-index: 1;
  width: 2.375rem;
  padding-right: 1.125rem;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  background: url('~assets/img/eye-outline.svg') no-repeat left center;

  &.show {
    background-image: url('~assets/img/eye-outline-slash.svg');
  }

  &:focus {
    border: none;
    outline: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.hidden {
  opacity: 0;
}

.modal-content {
  border: none;
}
