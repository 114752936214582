@import 'src/scss/BaseTheme.scss';
.activities {
  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-flow: row;
    padding: 0.625rem;
    border: 0.0625rem solid $grayLightExtraDark;
  }
}
