@import 'src/scss/BaseTheme.scss';

.userConnections-style {
  border: 0.0625rem solid $grayLightExtraDark;

  & .form-group {
    margin: 0;

    & .form-label {
      margin: 0;
    }
  }
}
