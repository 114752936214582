.payment-logo {
  font-size: 2rem;
}

.wallet-card-container {
  max-width: 29rem;
}

.expiration-date {
  max-width: 5rem;
}

.calender-icon {
  font-size: 1.5rem;
}

.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 30%;
  border: 3px solid black;
}

.td {
  border: 1px solid #dddddd;
  text-align: left;
  width: 25%;
}

.th {
  border: 1px solid #dddddd;
  text-align: left;
  width: 25%;
}
.tr:nth-child(even) {
  background-color: #dddddd;
}
.th1 {
  border: 1px solid #dddddd;
  text-align: center;
}

// .crad-v {
//   width: 525px;
//   height: 300px;
//   background: white;
//   padding: 5px;
// }

.imglogo-v {
  padding: 12px;
  position: relative;
  top: 0px;
}

.com-img {
  border-radius: 10px;
  border: 6px double #1c3a6b;
  width: 50px;
  height: 50px;
}

.com-logo {
  margin-bottom: 13px;
  border-radius: 10px;
  width: 75px;
  height: 75px;
}

span.mobileno {
  position: relative;
  left: 39px;
  top: -10px;
}

.cionbox {
  background: #1c3a6b;
  width: 34px;
  height: 34px;
  float: left;
  border-radius: 50%;
  text-align: center;
}
.op {
  position: relative;
  left: 10px;
}

.card-icon-new {
  border-radius: 50%;
  color: white;
  font-size: 16px;
  position: relative;
  top: 6px;
  text-align: center;
}
.crad-mobile {
  width: 330px;
  height: 270px;
  padding: 1px;
  border: 2px solid #fff;
  -webkit-box-shadow: 0px 0px 2px 5px #0000002a;
  box-shadow: 0px 0px 2px 2px #0000002a;
  margin-bottom: 31px;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.text-vcard {
  padding: 12px 0px;
}

div#top-vcard:before {
  content: '';
  position: absolute;
  height: 91px;
  width: 34px;
  text-align: left !important;
  background: #1c3a6b;
  left: 15px;
  top: -12px;
  border-radius: 0px 0px 14px 15px;
}

strong.font-bolder-new {
  font-size: 16px;
  font-weight: bolder !important;
}
