.id-proofing-login {
  .id-proofing {
    .choose-title {
      font-size: 1.125rem;
      font-weight: bold;
      text-align: center;
    }
    
    .img-logo {
      max-width: 5rem;
    }
    
    .inner-card {
      width: 18rem;
      margin-top: 2rem;
    }
    
    .card-title {
      margin-top: 2rem;
    }
  }
}