@import 'src/scss/BaseTheme.scss';

$primary-chatbot-color: #ef5d00;
$secondary-chatbot-color: $lightBlue;

.chatbot-connect-toggle {
  display: flex;
  align-items: center;
  background: $primary-chatbot-color;
  border-radius: 100%;
  border: none;
  bottom: 0.9375rem;
  box-shadow: $box-shadow-chatbot;
  color: $white;
  cursor: pointer;
  fill: $white;
  font-size: 1.5rem;
  height: 3.75rem;
  justify-content: center;
  position: fixed;
  right: 2rem;
  transition: transform 0.3s ease;
  width: 3.75rem;
  z-index: 999;

  & svg {
    transition: all 0.12s ease;
  }

  @media (max-width: 400px) {
    right: 0.5625rem;
  }
}

#chatbot-main-container {
  position: fixed;
  z-index: 1;
  top: unset;
  bottom: 0;
  right: 1.9375rem;
  left: unset;
  background: $lightGray;
  margin-top: 1rem;
  height: 30.5rem;
  width: 21.9375rem;
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);

  p {
    font-size: 0.875rem;
  }

  @media (max-width: 400px) {
    right: 0.5625rem;
    width: 18.9375rem;
  }

  & .loader {
    z-index: 9999;
    background-color: $white;
    position: initial;
    height: 100%;
  }

  &.dehydrated {
    -o-transform: scale(0);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  & .chatbot-header {
    background: $hue;
    font-weight: 700;
    color: $white;
    padding: 1rem;
    border: 0.125rem solid $hue;

    & .chatbot-session-end-btn {
      color: $white;
      background: transparent;
      border: none;
      font-size: 1.3rem;
    }
  }

  & .message-list-container {
    height: 23.9375rem;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column-reverse;
    -o-transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;

    & .chatbot-icons {
      max-width: 2.5rem;
    }

    & .agent-icon {
      max-height: 2.625rem;
    }
  }

  & .bubble {
    max-width: 100%;
    padding: 0.8em 1.4em;
    text-align: left;
    overflow-wrap: break-word;
    margin-bottom: 0.625rem;
    font-size: 0.875rem;
    padding: 0.875rem;
    margin-top: 0.375rem;
    border-radius: 0.3125rem;
    position: relative;
  }

  & .bot {
    background: $grayChatbot;
    &::after {
      display: block;
      content: ' ';
      position: absolute;
      left: -0.375rem;
      bottom: 0.25rem;
      border-radius: 0.125rem;
      border-left: 0.625rem solid transparent;
      border-right: 0.75rem solid transparent;
      border-bottom: 0.5625rem solid $grayChatbot;
    }
  }

  & .user {
    background: $orangeChatbot;
    padding: 0.875rem;
    margin-top: 0.375rem;
    border-radius: 0.3125rem;
    position: relative;
    &::after {
      display: block;
      content: ' ';
      position: absolute;
      right: -0.375rem;
      bottom: 0.25rem;
      border-radius: 0.125rem;
      border-left: 0.75rem solid transparent;
      border-right: 0.625rem solid transparent;
      border-bottom: 0.5625rem solid $orangeChatbot;
    }
  }

  & .chatBtn {
    font-weight: bold;
    border-radius: 0.625rem;
    width: 100%;
    line-height: 1.465;
    font-family: inherit;
    color: $black;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    border-width: 0rem;
    border-style: solid;
    padding: 0.45rem 0.625rem;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    max-width: 16.25rem;
    //background: linear-gradient($white, $white 80%, $white);
    border-color: $white;
    font-size: 0.875rem;

    &:hover {
      color: $white;
      background: $chatbotMessageBtnBlue;
      & span {
        color: $white;
      }
    }

    & span {
      color: $grayText-default;
    }

    &.chatBtn-link {
      display: block;

      &:hover {
        text-decoration: none;
      }
    }
  }

  & .user-text-container {
    & input {
      resize: none;
      min-height: 2.6875rem;
      max-height: 10.1875rem;
      width: 100%;
      border-left: 0rem;
      border-right: 0rem;
      border-top: 0.0625rem;
      border-bottom: 0.0625rem;
      border-style: solid;
      border-color: $grayMediumDark;
      padding: 0.625rem;

      &:focus-visible {
        outline: none;
      }
    }

    & .send-btn {
      font-size: 1.3rem;
      color: $primary-chatbot-color;
      border-top: 0.0625rem solid $grayMediumDark;
      border-bottom: 0.0625rem solid $grayMediumDark;
      display: flex;
      justify-content: center;
      align-items: center;

      -webkit-transition: color ease-out 0.3s;
      -moz-transition: color ease-out 0.3s;
      -o-transition: color ease-out 0.3s;
      transition: color ease-out 0.3s;
      &.empty {
        color: $grayText-default;
      }
    }
  }
}
