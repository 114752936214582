@import 'src/scss/_BaseTheme.scss';

$primary-color: $aegisOrange;

.wayfinder-horizontal {
  background: $lightSilver;
  border: 0.0625rem solid $lightSilver;
  height: 0.75rem;
  margin: 1.875rem 0 3.125rem;

  & ul {
    position: relative;
    top: -0.6875rem;
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 1.0625rem;

    & li {
      position: relative;
      min-height: 2.1875rem;
      text-align: center;
      margin: 0 auto;
      font-size: 0.8125rem;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: $black;

      & span {
        height: 2rem;
        width: 2.0625rem;
        display: block;
        margin: 0 auto 0.625rem;
        position: relative;
        border: 0.0625rem solid $grayLightDarker;
        border-radius: 1.5625rem;
        padding: 0.4375rem;
        color: $black;
        font-size: 1.3em;
        -webkit-box-shadow: 0 0 0.125rem $lightSilver;
        -moz-box-shadow: 0 0 0.125rem $lightSilver;
        box-shadow: 0 0 0.125rem $lightSilver;
        background: $white;
        font-weight: bold;
      }

      &.active {
        color: $black;
        font-weight: bold;

        & span {
          border: 0.0625rem solid $connecticutBlueDark;
          color: $white;
          background: $connecticutBlueDark;
        }
      }
    }
  }
}
