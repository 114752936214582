@import 'src/scss/BaseTheme.scss';

.chevron-style {
  font-size: 1.3rem;
  color: $grayLightDarker;
}

.new-indicator {
  background: $yellow;
  max-width: 5rem;
  border-width: 0.125rem;
  border-color: black;
}
