@import 'src/scss/BaseTheme.scss';
.unstyled-button {
  border: none;
  padding: 0;
  background: none;
}
.login-form {
  width: 70%;
}
.qrcode {
  max-height: 4rem;
}

.logo-img {
  max-height: 7rem;
}

.signup-button-container {
  margin-left: 14%;
  display: inline-block;
  width: 100%;
}
.signup-option-text {
  color: $aegisNavy;
  font-size: 1.05rem;
}
.hero-img-bg {
  //background-image: url('~assets/img/florida-homes-hero-image.jpg');
  background-size: cover;
  min-height: calc(100vh - (7.75rem + 5.5625rem));
  height: 100%;

  // @media (min-height: 787px) {
  //   height: calc(100vh - 7.6625rem - 6.1569rem);
  // }
}
.has-background {
  background-image: url('~assets/img/florida-homes-hero-image.jpg');
  background-size: cover;
  position: relative;
  overflow: hidden;
  height: 100%;

  // @media (min-height: 787px) {
  //   height: calc(100vh - 7.6625rem - 6.1569rem);
  // }
}

.login-password-eye {
  z-index: 0;
  top: 1.9375rem;
}

.login-container {
  .error-div {
    position: absolute;
    width: 100%;
    top: 0.3125rem;
    z-index: 999;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 600px;
    @media (max-width: 992px) {
      max-width: 390px;
    }
    @media (max-width: 766px) {
      position: inherit;
      margin-top: 3rem;
    }
  }
  .separator-login {
    width: 68%;
  }
  .radiobox-container {
    background: $white;
    padding: 3.375rem;
    position: relative;
    @media (max-width: 600px) {
      padding: 1.375rem;
      max-height: 31.25rem;
      overflow-y: scroll;
    }
    .choose-title {
      font-size: 1.125rem;
      font-weight: bold;
      text-align: center;
    }
    .login-radio-buttons .form-check-radio .form-check-radio-label {
      margin-top: 0.125rem;
      margin-left: 1rem;
    }
    .verification-code-input {
      width: 45%;
    }
  }

  .verification_container {
    background: $white;
    margin-bottom: 0 !important;
    padding: 3.75rem !important;
    height: 100vh;
  }
  .barcode-center {
    text-align: center;
  }

  canvas {
    width: 12.5rem !important;
    height: 12.5rem !important;
  }
  // .login-modal {
  //   box-shadow: 0 0.25rem 0.5rem 0 $grayLightDarker;

  // }
}

.waiting-response {
  height: 100vh;
}
.forget-username-mobile {
  padding-left: 0rem;
  @media (max-width: 576px) {
    padding: inherit;
  }
}
.forget-password-mobile {
  padding-right: 0rem;
  @media (max-width: 576px) {
    padding: inherit;
  }
}

/*
media queries for responsiveness
https://getbootstrap.com/docs/4.0/layout/grid/#variables
https://getbootstrap.com/docs/4.0/layout/overview/
*/
@media (max-width: 768px) {
  .hero-img-bg {
    display: none;
  }
  .verification-code-input {
    width: 100% !important;
  }
  // Extra small devices (portrait phones, less than 576px)s
}
.hypr {
  .choose-title {
    font-size: 2.125rem !important;
  }
  // input[type='text'] {
  //   width: 49% !important;
  // }
}

.passwordless{
  .choose-title {
    font-size: 2.125rem !important;
  }
  input[type='text'] {
    width: 49% !important;
  }
}
