.privacy-consent {
  .check {
    display: flex;
    align-items: center;
    .form-group {
        margin-left: 0.625rem;
        margin-top: 0.625rem;
        margin-bottom:0rem;
    }
  }
}