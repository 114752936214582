@import 'src/scss/BaseTheme.scss';
.gray-background {
    background-color: $alto
}
.wentwrong {
    padding-bottom:11rem;
     &__block {
        border-radius: 0.25rem 0.25rem 0 0;
        text-align:center;
        background-color: $white;
        box-shadow: 0 0.25rem 0.5rem 0 $mystic;
        margin: 4rem 0;
        padding: 2.5rem 1.25rem;
        img {
            padding-top:3rem;
            padding-bottom:3rem;
        }
        h1,h6 {
            padding-bottom:1rem;
        }
        .resend-link {
            text-decoration: underline;
            color: $connecticutBlueDark;
            cursor: pointer;
        }
     }
}