@import 'src/scss/BaseTheme.scss';
.site-settings {
  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-flow: row;
    padding: 0.625rem;
  }
  & .site-setting-dropdowns {
    & .dropdown-menu.show {
      overflow-x: hidden;
      overflow-y: scroll;
      max-height: 16rem;
    }
    & button.dropdown-toggle {
      color: $black;
      background-color: $white;
      border: 0.0625rem solid $alto;
      white-space: break-spaces;
      padding-right: 2rem;
    }
    & button.dropdown-toggle:hover {
      background-color: $white;
      border: 0.0625rem solid $alto;
      white-space: break-spaces;
      padding-right: 2rem;
    }

    & .dropdown-item {
      white-space: pre-wrap;
    }
  }
}
