.popupBackground-idproofing {
    top: -0.05rem;
    width: 100vw;
    height: 100vh;
    background-color: rgba(146, 144, 144, 0.7);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    overflow:hidden
  }
  
  .popupContainer-idproofing {
    width: 750px;
    height: 600px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }
  
  .popupContainer-idproofing .popupTitle-idproofing {
    display: inline-block;
    text-align: center;
    margin-top: 1.5rem;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn img {
    width: 30px;
    height: 30px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35);
    margin-right: -0.5rem;
  }
  
  .popupContainer-idproofing .popupBody-idproofing {
    flex: 50%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    font-size: 1.3rem;
    text-align: center;
  }

  .popupContainer-idproofing .popupBody-idproofing-image{
    margin: 3rem;
  }

  .popupBody-progressBar{
    height: 500px;
    width: 100%;
  }
  
  .popupContainer-idproofing .popupFooter-idproofing {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popupContainer-idproofing .popupFooter-idproofing button {
    width: 150px;
    height: 60px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
    // padding: 2rem 3rem;
    border-radius: 0.9rem;
    font-size: 1rem;
    font-style: bold;
    text-transform: uppercase;      
    transition: all .2s;  
    transform: translateY(-2px);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .popupContainer-idproofing .popupFooter-idproofing button:hover {
        transform: translateY(-4px);
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

  .popupContainer-idproofing .popupFooter-idproofing button:active {
        transform: translateY(-1px);
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    }
  
  #cancelBtn {
    background-color: crimson;
  }