@import 'src/scss/BaseTheme.scss';
@import 'src/scss/Typography.scss';

.profile {
  padding: 0;
  min-height: 27.18rem;
  overflow: auto;
  background: $grayExtraLight;
  &__row {
    &__left {
      box-shadow: 0 0.125rem 0.25rem 0 $mystic;
      background: $white;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 1;
    }
    &__right {
      padding: 3.5rem 4.5rem !important;
    }
    .titles-container {
      .title-border {
        border: 0.0625rem solid $grayLightDark;
        margin-right: 1.25rem;
        padding: 1.25rem;
        background: $easternBlue;
        color: $white;
        svg {
          width: 1.875rem;
          height: 3.125rem;
        }
      }
    }
  }
}
