@import 'src/scss/BaseTheme.scss';
.account-management {
  &__container {
    img {
      width: 12.5rem;
      height: 12.5rem;
    }
    align-items: center;
    margin: auto 0;
    .content {
      margin-left: 1.25rem;
      color: $lightSlategray;
    }
  }
}
