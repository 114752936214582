.table {
  .dropdown {
    button {
      margin-top:25px;
    }
    .dropdown-toggle:before {
      content: "";
      position: absolute;
      
      background: url('~assets/img/arrow-down.png')
        no-repeat center;
      background-size: 100%;
      
      width: 1.2rem;
      height: 0.775rem;
      right: 1rem;
      top: 48%;

    }
  }

}
.admin-title {
  display:flex;
  align-items: center;
  justify-content: space-between;
}