@import 'src/scss/_BaseTheme.scss';
.dropdown {
    a {
      text-decoration: none;
    }
    .btn {
      text-transform: initial;
    }
    .btn.focus {
      box-shadow: none;
    }
    Button {
      width: 100% !important;
      color: $mineshaft;
      background-color: $white;
      border: 1px solid $alto;
      text-align: left;
      padding-left: 1.75rem;
    }
  
    .dropdown-toggle:before {
      content: "";
      position: absolute;
      
      background: url('~assets/img/down_arrow.png')
        no-repeat center;
      background-size: 100%;
      
      width: 0.9375rem;
      height: 0.775rem;
      right: 1rem;
      top: 5%;
      bottom: 0;
      margin: auto;

    }
    .dropdown-toggle:after {
      display: none;
    }
  
    .btn-primary:focus {
      color: #2d2c2c;
      background-color: $white !important;
      border: 1px solid $alto !important;
    }
  
    margin-bottom: 30px;
  }
  
  .dropdown-menu.show {
    width: 100%;
  }
  .show .btn-primary.dropdown-toggle,
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .dropdown-item.active,
  .dropdown-item:active {
    color: #2d2c2c !important;
    background-color: $white !important;
    border: 1px solid $alto !important;
  }
  