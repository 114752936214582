@import 'src/scss/BaseTheme.scss';
.change-request-access {
  .terms-condition {
    max-height: 12.5rem;
    overflow-x: none;
    overflow-y: scroll;
    font-size: 0.8em;
    margin-bottom: 0.625rem;
    padding: 0.3125rem;
    border: solid 1px $Lochinvar;
  }
}
.question-form {
  padding-top: 3rem;
  position: relative;
  margin: auto;
  width: 100%;
}
