.id-proofing-login{
    // height: ;
    body{
        font-family: "Lato", sans-serif;
    }
    
    .grid-container-idproofing{
        margin: 0;
        padding: 0;
        display: flex;
        margin-top: -4rem;
        justify-content: center;
        
    }
    
    .grid-even-columns-idproofing{
        display: grid;
        gap: 3em;
        padding: 0rem 1rem 1rem 4rem;
        justify-content: center;
        
    }   
    
    .flow-idproofing{
        margin-top: 6rem;
        padding: 0rem 4rem 0rem;    
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.5s ease;
        cursor: pointer;
    }
    
    .flow-idproofing:hover {
        transform: scale(1.1);
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }
    
    .image-grid-idproofing{
        margin: auto;
        padding: 1rem;
        display: flex;
        justify-content: center;   
    }
    
    h5,p{
        text-align: center;
    }

    .number-container-idproofing{
        display: flex;
        justify-content: center;
    }

    .numbering-idproofing{
        margin: 1.5rem;
        background: #003e51;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        display: flex;
        justify-content: center;
        color: #fff;
        font-weight: bold;
        padding-top: 0.2rem;
        font-size: 20px !important;
        width: 40px;
        height: 35px;
        line-height: 40px;
        text-align: center;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    }

    
    @media (min-width:65rem) {
        .grid-even-columns-idproofing{
            grid-template-columns: repeat(2,2fr);
        }
    }
    
    @media (min-width:80rem) {
        .grid-even-columns-idproofing{
            grid-template-columns: repeat(4,2fr);
        }
    }

    .threeDot-idproofing{
        display: block;
    }
    
    .startButton-idproofing,
    .startButton-idproofing:visited{
        display: flex;
        justify-content: center;
        background-color: #212121;
        color: #fff;
        padding: 0.5rem 2.5rem;
        border-radius: 0.9rem;
        font-size: 1.3rem;
        font-weight: 600;
        text-transform: uppercase;      
        transition: all .2s;  
    }
    
    .startButton-idproofing:hover {
        transform: translateY(-3px);
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    .startButton-idproofing:active {
        transform: translateY(-1px);
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    }
    
    .buttonWrapper-idproofing{
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }
}