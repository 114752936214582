@import 'src/scss/BaseTheme.scss';
.registration {
  padding-left: 3.125rem;
  padding-right: 3.125rem;
  padding-top: 1.25rem;
  @media (max-width: 560px) {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }
  &__container {
    padding: 1.5625rem;
    margin-bottom: 1.25rem;
    &__resend-verification {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      &__resend-text {
        .resend-link {
          text-decoration: underline;
          color: $connecticutBlueDark;
          cursor: pointer;
        }
      }
    }
    &__pleasenote {
      border: 0.0625rem solid $SandyBrown;
      border-radius: 0.25rem;
      background: $seaShellPeach;
      margin-top: 0.53125rem;
      margin-bottom: 0.53125rem;
      width: 45%;
      padding: 1rem;
      @media (max-width: 768px) {
        width: 100%;
      }
      &-fp {
        color: $bullShot;
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }
  .google-captcha-title {
    font-weight: bold;
  }
  Select {
    margin: 1rem;
  }
  .answer-input {
    margin-top: 20px;
  }
  .select-box {
    width: 50%;
  }
  .answer-input {
    width: 50%;
  }
}
.registration-form {
  height: auto;
  .col {
    padding-top: 1.25rem;
    padding-bottom: 1.875rem;
  }
}