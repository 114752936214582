@import 'src/scss/BaseTheme.scss';

// Font and Typography classes go here
body,
html {
  font-size: 1rem;
  scroll-behavior: smooth;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
}

.hero-font {
  font-size: 3.75rem !important;
}

h1 {
  font-size: 2.5rem !important;
  font-weight: bold !important;
  line-height: 3.25rem !important;
}

//abstract Header 2
h2 {
  font-size: 2rem !important;
  font-weight: bold !important;
  line-height: 2.5rem !important;
}

//abstract Header 3
h3 {
  font-size: 1.5rem !important;
  font-weight: bold !important;
  line-height: 2rem !important;
}

//abstract Header 4
h4 {
  font-size: 1.3125rem !important;
  font-weight: bold !important;
  line-height: 1.875rem !important;
}

//abstract Header 5
h5 {
  font-size: 1.125rem !important;
  font-weight: bold !important;
  line-height: 1.75rem !important;
}

.ft-24 {
  //font size 24px
  font-size: 1.5rem;
}

.ft-18 {
  //font size 18px
  font-size: 1.125rem;
}

.ft-14 {
  //font size 14px
  font-size: 0.875rem;
}

.ft-12 {
  //font size 12px
  font-size: 0.75rem;
}

.ft-10 {
  //font size 10px
  font-size: 0.625rem;
}

legend {
  font-size: 1rem !important;
}

label {
  font-size: 1rem;
  font-weight: bold;
}

a {
  cursor: pointer;
  color: $connecticutBlueDark;
  text-decoration: underline;
}

textarea {
  resize: none;
}
