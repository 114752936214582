.payment-logo {
  font-size: 2rem;
}

.wallet-card-container {
  max-width: 29rem;
}

.expiration-date {
  max-width: 5rem;
}

.calender-icon {
  font-size: 1.5rem;
}
