.payment-logo {
  font-size: 2rem;
}

.wallet-card-container {
  max-width: 29rem;
}

.expiration-date {
  max-width: 5rem;
}

.calender-icon {
  font-size: 1.5rem;
}

.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 5%;
  border: 3px solid black;
}

.td {
  border: 1px solid #dddddd;
  text-align: left;
  width: 15%;
}

.th {
  border: 1px solid #dddddd;
  text-align: left;
  width: 15%;
}
.tr:nth-child(even) {
  background-color: #dddddd;
}
.th1 {
  border: 1px solid #dddddd;
  text-align: center;
}

.crad-v {
  display: grid;
  width: 525px;
  height: auto;
  background: white;
  padding: 5px;
}

.imglogo-v {
  padding: 12px;
  position: relative;
  top: 0px;
}

.com-img {
  border-radius: 10px;
  border: 6px double #1c3a6b;
  width: 100px;
  height: 100px;
}

.com-logo {
  margin-bottom: 13px;
  height: 100px;
}

span.mobileno {
  position: relative;
  left: 39px;
  top: -10px;
}

.cionbox {
  background: #1c3a6b;
  width: 34px;
  height: 34px;
  float: left;
  border-radius: 50%;
  text-align: center;
}
.op {
  position: relative;
  left: 10px;
}

.card-icon-new {
  border-radius: 50%;
  color: white;
  font-size: 16px;
  position: relative;
  top: 6px;
  text-align: center;
}

.crad-v {
  width: 525px;
  height: 250px;
  background: url(https://image.freepik.com/free-vector/bright-background-with-dots_1055-3132.jpg);
  padding: 1px;
  border: 2px solid #fff;
  -webkit-box-shadow: 0px 0px 2px 5px #0000002a;
  box-shadow: 0px 0px 2px 2px #0000002a;
  margin-bottom: 31px;
  margin-top: 0px;
  font-size: medium;
  font-style: inherit;
}

.crad-uni {
  width: 525px;
  height: 250px;
  padding: 1px;
  border: 2px solid #fff;
  -webkit-box-shadow: 0px 0px 2px 5px #0000002a;
  box-shadow: 0px 0px 2px 2px #0000002a;
  margin-bottom: 31px;
  margin-top: 0px;
  font-size: medium;
  font-style: inherit;
  background: url('../../../assets/img/credBack.png');
  background-size: contain;
}

.text-vcard {
  padding: 42px 0px;
}
.text-center {
  text-align: center;
}

.alldetail {
  padding: 0px 0px;
  font-size: 15px;
}

div#top-vcard:before {
  content: '';
  position: absolute;
  height: 91px;
  width: 64px;
  text-align: left !important;
  background: #1c3a6b;
  left: 15px;
  top: -12px;
  border-radius: 0px 0px 14px 15px;
}

strong.font-bolder-new {
  font-size: 15px;
  font-weight: bolder !important;
}

.allDetail-font {
  font-size: 12px;
  font-weight: bolder !important;
}
//css grid
.grid-container {
  display: grid;
  grid-template-columns: 1fr auto;

  /* First grid takes 1 fraction, second takes remaining space */
}

.grid-item {
  border: 3px solid #ddd;
  padding: 1px;
  width: 620px;
  height: auto;
  margin-left: 50px;
  font-size: 18px;
  font-style: normal;
}

.item1 {
  margin-left: 50px;
  height: auto;
  background-color: #eee;
}

.item2 {
  margin-right: 50px;
  height: auto;
  background-color: #f5f5f5;
}
