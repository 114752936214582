@import 'src/scss/BaseTheme.scss';
#pease_note {
  width: 33%;
}
.forgot-username {
  background-color: $white;
  border-radius: 0.25rem 0.25rem 0 0;
  box-shadow: 0 0.25rem 0.5rem 0 $mystic;
  padding: 1.5625rem;
  margin-bottom: 1.25rem;
  .form-check-radio {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .form-check-radio-container {
    align-items: center;
    display: flex;
    input {
      width: 1rem;
      height: 1rem;
    }
  }
  .form-check-radio-label {
    margin-left: 0.625rem;
    margin-top: 0.125rem;
  }
}
