@import 'src/scss/BaseTheme.scss';
.notification-label {
  label {
    width: 36%;
  }
  margin-bottom: 1.25rem;
}

#app-icon {
  width: 20%;
  height: 100%;
}
.app-div {
  height: 5rem;
}
