@import 'src/scss/BaseTheme.scss';
.change-password {
    &__container {
        background:$white;
        align-items: center;
        padding: 0 2rem;
        margin-bottom: 3.25rem;
        box-shadow: 0 0.25rem 0.5rem 0 #e7e9f2;
        &-row {
          padding: 2rem 0 2rem 2.5rem;
          &-text {
            margin:auto;
            &-link {
            color:$tiber;
            text-decoration:underline;
            cursor:pointer;
            }
          }
        }
    }
  .inside-button {
    width:1rem;
    height:1rem;
  }
}
.personal-information {
  .select-box {
    margin-bottom: 20px;
  }
  .security-container {
    display:block;
  }
}