@import 'src/scss/BaseTheme.scss';
.edit-email-pop {
    .error-input {
        border-color: $errorDark;
        padding-right: 3.5rem;
    }
    .change-password-popup__popup-body {
        padding: 2rem 2rem 2rem 2rem;
    }
    .registration__container {
        padding: 0;
        margin-bottom: 0;
    }
    .pleasenote {
        width:100%;
    }
}
.email-popup-parent{
    z-index: 4;
}