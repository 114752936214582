@import 'src/scss/BaseTheme.scss';
@import 'src/scss/Typography.scss';

.search-container {
  display: flex;
  .search-icon {
    height: 2.4rem;
  }
}

.display-container {
  width: 200vh;
  margin: 2rem;
}

.error {
  color: red;
}

.addUser {
  color: #fff;
  right: -8.5rem !important;
  position: absolute;
  cursor: pointer;
  background: #01699d;
  border-color: #01699d;
  border-radius: 5px;
  padding: 7px 20px;
  text-align: center;
}

.disabled {
  color: #495057;
  right: -8.5rem !important;
  position: absolute;
  cursor: pointer;
  background: #e9ecef;
  border-color: #ced4da;
  border-radius: 5px;
  padding: 7px 20px;
  text-align: center;
}

.search-row {
  margin-left: 12%;
  // width: 50%;
  display: inline-block;
}

.user-table {
  width: 96%;
  margin-left: 2%;
}

.search-dropdown {
  min-width: 10rem;
}

.search-input {
  min-width: 15rem;
}

.statusbtn-inactive {
  padding: 0 1px 0 1px;
  background-color: grey;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.statusbtn-active {
  padding: 0 1px 0 1px;
  background-color: #01699d;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.search-container {
  .show .btn-primary.dropdown-toggle,
  .dropdown .btn-primary:focus {
    background-color: #01699d !important;
    border-color: #01699d !important;
    color: #ffffff !important;
  }
}

.page-link {
  color: #01699d;
}

.page-item.active .page-link {
  background-color: #01699d;
  border-color: #01699d;
}

.pagination {
  position: absolute;
  right: 15px;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}
