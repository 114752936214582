@import 'src/scss/BaseTheme.scss';

.nav-bar-width {
  max-width: 14.2499%;
}

.main-menu {
  background: $grayLightDark;
  margin: 0 auto;
  &__title {
    display: none;
    color: $grayExtraDark;
    font-size: 1.5rem;
    letter-spacing: 0.035rem;
    line-height: 1.75rem;
    padding: 0rem 2.5rem;
  }
  &__options {
    list-style: none;
    display: flex;
    padding: 0;
    background: $connecticutBlueDark;
    a {
      color: $white;
      &:hover {
        color: $white;
      }
    }
    li {
      padding: 1rem;
      text-align: center;
      display: table-cell;
      &.active:not(.brand-bar) {
        @media (min-width: 768px) {
          border-bottom: 0.5625rem solid $white;
        }
      }
      svg {
        display: block;
        width: 2.125rem !important;
        height: 2.5rem !important;
      }
      & .notificationAlerts {
        position: absolute;
        top: -27%;

        left: 52%;
        padding: 0.25rem 0.6875rem;
        border-radius: 50%;
        background-color: $white;
        color: $black;
      }
    }
    & .navbar-toggle {
      border: none;
    }
  }
}

.nav-style {
  margin-bottom: 16px;
}
.current-menu-style {
  position: relative;
  left: 28px;
}

@media (max-width: 768px) {
  // medium to extra small devices (less than 768px)
  .nav-bar-width {
    max-width: 100%;
  }
  .main-menu__options li .notificationAlerts {
    left: 27%;
  }
}

@media (min-width: 576px) {
  // Extra small devices (portrait phones, less than 576px)

  .main-menu {
    &__title {
      margin-bottom: 1.25rem;
    }
  }
}
