@import 'src/scss/BaseTheme.scss';
.password-terms {
  padding: 0;
  margin: 0;
  margin-left: 1.25rem;
  margin-bottom: 2rem;
  list-style: unset;
  li {
    font-size: 0.875rem;
    letter-spacing: 0.02px;
    line-height: 1rem;
    color: $grayExtraDark;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    background-size: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
