// UI Theme classes go here

// Theme colors
$aegisNavy: #00405d;
$aegisOrange: #ef5d00;
$lightBlue: #006390;
$alto: #d0cfcf;
$black: #000000;
$blackPearl: #072235;
$bullShot: #8a5321;
$connecticutBlueDark: #09538b;
$disarm: #01699d;
$hue: #003E5C;
$easternBlue: #17a2b8;
$errorDark: #db2426;
$errorLight: #fff0f1;
$galaxyBlue: #2b4b7d;
$gallery: #eeeeee;
$grayChatbot: #e4e2e2;
$grayDark: #757575;
$grayExtraDark: #2d2c2c;
$grayExtraLight: #f7f9fa;
$grayLightDark: #ecebeb;
$grayLightDarker: #b3b3b3;
$grayLightExtraDark: #595959;
$grayMediumDark: #d0cfcf;
$grayText-default: #757575;
$green: #1e7e34;
$lightGray: #f6f8fa;
$lightSilver: #d8d8d8;
$lightSilverDark: #d4d4d4;
$lightSlategray: #778899;
$Lochinvar: #28807c;
$midNightBlueDark: #053756;
$mineshaft: #2d2c2c;
$mystic: #e7e9f2;
$orangeChatbot: #ff8334;
$riverBlueDark: #3a95d3;
$riverBluelight: #e0f2ff;
$riverBlueMedium: #87bee3;
$chatbotMessageBtnBlue: #06adcb;
$SandyBrown: #f6a053;
$seaShellPeach: #fff8f2;
$secondaryColor: #d87f26;
$tiber: #092940;
$white: #ffffff;
$yellow: yellow;

// box shadow
$box-shadow-default: 0rem 0.375rem 0.5625rem 0rem rgba($black, 0.4);
$box-shadow-chatbot: 0rem 0.375rem 0.5625rem 0rem rgba($black, 0.1);
$box-shadow-light: 0rem 0.175rem 0.3625rem 0rem rgba($black, 0.2);

.box-shadow {
  box-shadow: 0 0.5rem 1rem 0 $tiber;
  -webkit-box-shadow: 0 0.5rem 1rem 0 $tiber;
}
.box-shadow-light {
  box-shadow: $box-shadow-light;
  -webkit-box-shadow: $box-shadow-light;
}
.box-shadow-default {
  box-shadow: $box-shadow-default;
  -webkit-box-shadow: $box-shadow-default;
}

// Scroll Bars
.nice-scrollbar::-webkit-scrollbar {
  width: 0.4375rem;
}

.nice-scrollbar::-webkit-scrollbar-track {
  background-color: $lightSilver;
  border-radius: 6.25rem;
}

.nice-scrollbar::-webkit-scrollbar-thumb {
  background-color: $grayDark;
  border-radius: 6.25rem;
}
