@import 'src/scss/BaseTheme.scss';

.home-page {
  &__container {
    margin-top: -1rem;
    img {
      width: 100%;
      height: 100%;
    }
    position: relative;
    &-content {
      // position: absolute;
      // top: 0rem;
      h1 {
        text-transform: capitalize;
        text-align: left;
      }
      &-wrapper {
        padding: 31px;
        color: $black;
        &__title {
          font-size: 1.5rem;
          padding-top: 1.875rem;
        }
      }
      & .image-banner {
        height: 18.75rem;
        background-image: (url('~assets/img/tampa-bay-hero-image.jpg'));
        background-position: 50% 28%;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 0.3125rem;
        box-shadow: $box-shadow-chatbot;
        display: flex;
        align-items: flex-end;
      }
    }
  }
}
