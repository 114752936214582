@import 'src/scss/_BaseTheme.scss';

/****
Button classes go here
****/

// set btn color theme
$primary-btn-color: $disarm;
$secondary-btn-color: $aegisOrange;

// button dark variants
$darkenedPrimaryColor1: darken(
  $color: $primary-btn-color,
  $amount: 10%,
);
$darkenedPrimaryColor2: darken(
  $color: $primary-btn-color,
  $amount: 20%,
);
$darkenedSecondaryColor1: darken(
  $color: $secondary-btn-color,
  $amount: 10%,
);
$darkenedSecondaryColor2: darken(
  $color: $secondary-btn-color,
  $amount: 20%,
);
$darkenedSuccessColor: darken(
  $color: $green,
  $amount: 10%,
);

.btn {
  // primary button
  &.btn-primary {
    color: $white;
    background-color: $primary-btn-color;
    border-color: $primary-btn-color;
  }
  &.btn-primary {
    background-color: $primary-btn-color;
    border-color: $primary-btn-color;
    color: $white;

    &.hollow {
      background-color: transparent;
      border: 0.0625rem solid $primary-btn-color;
      color: $primary-btn-color;

      &:hover {
        border-color: $darkenedPrimaryColor2;
        color: $darkenedPrimaryColor2;
      }
      &:focus {
        border-color: $darkenedPrimaryColor2;
        color: $darkenedPrimaryColor2;
        box-shadow: none;
      }
      &:active {
        background-color: transparent;
      }
    }

    &:hover:not(.hollow) {
      background-color: $darkenedPrimaryColor1;
    }

    &.btn:focus:not(.hollow) {
      background-color: $darkenedPrimaryColor1;
      box-shadow: 0 0 0 0.2rem rgba($primary-btn-color, 0.4);
    }
  }
  // secondary button
  &.btn-secondary {
    background-color: $lightBlue;
    color: $white;
    border: $lightBlue;
    &.hollow {
      background-color: transparent;
      border: 0.0625rem solid $lightBlue;
      color: $lightBlue;

      &:hover {
        border-color: $connecticutBlueDark;
        color: $connecticutBlueDark;
      }
      &:focus {
        border-color: $connecticutBlueDark;
        color: $connecticutBlueDark;
        box-shadow: none;
      }
      &:active {
        background-color: transparent;
      }
    }

    &:hover:not(.hollow) {
      background-color: $connecticutBlueDark;
    }

    &.btn:focus:not(.hollow) {
      background-color: $connecticutBlueDark;
      box-shadow: 0 0 0 0.2rem rgba($connecticutBlueDark, 0.4);
    }
  }
  // success button
  &.btn-success {
    background-color: $green;
    border-color: $green;

    &:hover {
      border-color: $darkenedSuccessColor;
      background-color: $darkenedSuccessColor;
    }
    &:focus {
      border-color: $darkenedSuccessColor;
      background-color: $darkenedSuccessColor;
    }
    &:active {
      background-color: transparent;
    }
  }
}

// button sizes
.btn-small {
  width: 6.25rem;
}

// button radius
.btn-rounded-0 {
  border-radius: 0;
}
