@import 'src/scss/BaseTheme.scss';
.modal-backdrop {
    position: fixed;
    background-color: $lightSilver;
    opacity: 0.6;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
  .change-password-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 7.5rem 0 1.25rem 0;
    overflow: auto;
    top: 0;
    z-index: 2;
    left: 0;
    &__popup {
      background: $white;
      border-radius: 0.25rem;
      max-width: 36.25rem;
      margin: auto;
      &-body {
        padding: 2rem 2rem 0 2rem;
        .error-text {
          margin-bottom: 0.1875rem;
        }
        &-header {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin-bottom: 1.5rem;
          h6 {
            font-size: 1.375rem;
            letter-spacing: 0.0325rem;
            line-height: 1.6875rem;
          }
          &-close {
            height: 1.75rem;
            width: 1.75rem;
            padding: 0;
            cursor: pointer;
            background: none;
            &:hover,
            &:active {
              background: none !important;
              border: none !important;
              box-shadow: none !important;
            }
            &:focus {
              background: none !important;
              box-shadow: none !important;
            }
            img {
              height: 1.75rem;
              width: 1.75rem;
            }
          }
        }
      }
      &-footer {
        padding: 1.75rem 2rem;
        border-top: 0.0625rem solid $grayLightDark;
      }
    }
  }
  