@import 'src/scss/BaseTheme.scss';
@media (max-width: 575.98px) {
  // Extra small devices (portrait phones, less than 576px)

  .disabled-link {
    pointer-events: none;
  }
  .profile-menu {
    &__title {
      color: $grayExtraDark;
      font-size: 1.5rem;
      letter-spacing: 0.035rem;
      line-height: 1.75rem;
      padding: 0rem 2.5rem;
      margin-bottom: 1.25rem;
    }
  }
}
@media (min-width: 576px) {
  // Extra small devices (portrait phones, less than 576px)
  .profile-menu {
    margin-top: 3.5rem;
    position: sticky;
    position: -webkit-sticky;
    &__title {
      color: $grayExtraDark;
      font-size: 1.5rem;
      letter-spacing: 0.035rem;
      line-height: 1.75rem;
      padding: 0rem 2.5rem;
      margin-bottom: 1.25rem;
    }
    &__options {
      li {
        padding: 1.35rem 0rem 1.35rem 2.5rem;
        border: 0.0625rem solid #ecebeb;
        &.active {
          color: $connecticutBlueDark;
          background-color: $grayExtraLight;
        }
        a {
          &.active {
            color: $connecticutBlueDark;
            background-color: $grayExtraLight;
          }
          svg {
            color: $connecticutBlueDark;
          }
        }
      }
      span {
        padding-left: 0.625rem;
        color: $grayExtraDark;
      }
    }
  }
}
