.container-idproofing-banner{
    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    
    body{
        font-family: "Lato", sans-serif;
    }
    
    .idproofing-banner-wrapper{
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        perspective: 1px;
        box-shadow: 5px 5px 10px 10px rgba(0,0, 0,0.2);
        display: flex;
        max-width: 100%;
        margin-top: -2rem;
        width: 96.6%;
        position: absolute;
        padding-bottom: 2.375rem;
    }
    
    .idproofing-banner-animation{
        background: rgb(255, 255, 255);
        color: #fff;
        padding: 10px;
        width: 250px;
        display: flex;
        justify-content: center;
        position: relative;
        
    }
    
    .idproofing-info{
        padding: 20px;
        position: relative;
        width: 100%;
    }
    
    .idproofing-info h4{
        letter-spacing: 1px;
        text-transform: uppercase;
        text-align: left;
    }
    
    .idproofing-info h5{
        letter-spacing: 1px;
        text-align: left;
        margin-top: 10px;
    }

    .idproofing-steps{
        position: absolute;
        left: 0;
        display: flex;
        flex-wrap: wrap;
        right: 0.5%;
    }
    
    .idproofing-bullet-wrapper{
        display: flex;
        align-items: right;
        padding: 0.8, 0, 0, 0.8rem;
    }

    .idproofing-bullet-wrapper h6{
        margin: 1rem;
        margin-left: -0.2rem;
        text-align: left;
        left: 2rem;
    }
    
    .img-bullet{
        height: 20px;
    }

    .startButton-idproofing,
    .startButton-idproofing_skip,
    .startButton-idproofing:visited{
        
        bottom: 1rem;
        right: 16rem;
        background-color: #33AA21;
        border: none;
        color: #fff;
        padding: 1rem 3rem;
        border-radius: 0.9rem;
        font-size: 1rem;
        text-transform: uppercase;      
        transition: all .2s;  
        transform: translateY(-2px);
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    .startButton-idproofing_skip{
        right: 2rem;
        background-color: #989898;
        border: none;
    }
    
    .startButton-idproofing:hover,
    .startButton-idproofing_skip:hover {
        transform: translateY(-4px);
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    .startButton-idproofing:active,
    .startButton-idproofing_skip:active {
        transform: translateY(-1px);
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    }
    
    .buttonWrapper-idproofing{
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }
    
}